import ApiService from '../api.service';

const AdjustmentReasonsResource = {
  index: (params) => ApiService.query('managers/adjustment_reasons', params),
  create: (params) => ApiService.post('managers/adjustment_reasons', params),
  update: (params) => ApiService.put(`managers/adjustment_reasons/${params.id}`, params),
  toggleActivation: (params) => ApiService.put(`managers/adjustment_reasons/${params.id}/toggle_activation`, params),
};

export default AdjustmentReasonsResource;

import DriverProfile from '@/api/resources/driver_profiles';
import i18n from '../../plugins/i18n';

const state = {
  driverProfiles: [],
  profileRegisterSuccess: false,
};

const getters = {
  getDriversProfiles: () => state.driverProfiles,
  getProfileRegisterSuccess: () => (state.profileRegisterSuccess),
};

const actions = {
  fetchDriverProfiles({ commit }, payload) {
    DriverProfile.index(payload).then((response) => {
      commit('setDriverProfiles', response);
    });
  },

  createDriverProfile({ commit, dispatch }, payload) {
    commit('setProfileRegisterSuccess', false);

    DriverProfile.create(payload).then(() => {
      dispatch('addSuccessMessage', i18n.t('components.lists.profiles.register.success_message'));
      commit('setProfileRegisterSuccess', true);
      dispatch('removeLoading');
    });
  },

  updateDriverProfile({ commit, dispatch }, payload) {
    commit('setProfileRegisterSuccess', false);

    DriverProfile.update(payload).then(() => {
      dispatch('addSuccessMessage', i18n.t('components.lists.profiles.edit.success_message'));
      commit('setProfileRegisterSuccess', true);
      dispatch('removeLoading');
    });
  },

  toggleActivationProfile({ commit, dispatch }, payload) {
    commit('setProfileRegisterSuccess', false);

    DriverProfile.toggleActivation(payload).then(() => {
      dispatch('addSuccessMessage', i18n.t('components.lists.profiles.toggle_activation.success_message'));
      commit('setProfileRegisterSuccess', true);
      dispatch('removeLoading');
    });
  },
};

const mutations = {
  setDriverProfiles($state, payload) {
    const stateCopy = $state;
    stateCopy.driverProfiles = payload;
  },

  setProfileRegisterSuccess($state, payload) {
    const stateCopy = $state;
    stateCopy.profileRegisterSuccess = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

import ApiService from '../api.service';

const WorkdayUpdatesResource = {
  index: (params) => ApiService.query('managers/workday_updates', params),
  show: (params) => ApiService.get(`managers/workday_updates/${params.id}`),
  approve_update: (params) => ApiService.put('managers/workday_updates/approve_update', params),
  reject_update: (id) => ApiService.put('managers/workday_updates/reject_update', id),
  changeToDayOff: (params) => ApiService.post(`managers/workdays/${params.id}/create_slack`, params),
  changeDsrSuppressed: (params) => ApiService.post(`managers/workdays/${params.id}/change_dsr_suppressed`, params),
  create: (params) => ApiService.post('managers/workday_updates', params),
};

export default WorkdayUpdatesResource;

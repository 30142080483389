import ApiService from '../api.service';

const HolidayResource = {
  create: (params) => ApiService.post('/managers/holidays/', params),
  index: () => ApiService.get('/managers/holidays/'),
  delete: (params) => ApiService.delete(`managers/holidays/${params.id}`, params),
  update: (params) => ApiService.put(`/managers/holidays/${params.id}`, params),
};

export default HolidayResource;

import ApiService from '../api.service';

const VacationsResource = {
  index: (params) => ApiService.get(`managers/users/${params.userId}/vacations`),
  create: (params) => ApiService.post(`managers/users/${params.userId}/vacations`, params),
  update: (params) => ApiService.put(`managers/users/${params.userId}/vacations/${params.id}`, params),
  delete: (params) => ApiService.delete(`managers/users/${params.userId}/vacations/${params.id}`, params),
};

export default VacationsResource;

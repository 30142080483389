import Vue from 'vue';
import Vuex from 'vuex';

import Loading from './modules/loading';
import Session from './modules/session';
import Snackbar from './modules/snackbar';
import Error from './modules/error';
import User from './modules/user';
import PointMirror from './modules/point_mirror';
import Notification from './modules/notification';
import Manager from './modules/manager';
import State from './modules/state';
import WorkdayUpdate from './modules/workday_update';
import Workday from './modules/workday';
import Advertence from './modules/advertence';
import Timesheet from './modules/timesheet';
import Branch from './modules/branch';
import BusinessUnit from './modules/business_unit';
import Tabs from './modules/tabs';
import DriverProfile from './modules/driver_profile';
import Breadcrumbs from './modules/breadcrumb';
import Holiday from './modules/holiday';
import Timezone from './modules/timezone';
import AdjustmentReason from './modules/adjustment_reason';
import Vacation from './modules/vacation';
import SearchFilter from './modules/search_filter';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    User,
    Loading,
    Session,
    Snackbar,
    Error,
    Notification,
    PointMirror,
    Manager,
    State,
    WorkdayUpdate,
    Workday,
    Advertence,
    Timesheet,
    Branch,
    BusinessUnit,
    Tabs,
    DriverProfile,
    Breadcrumbs,
    Holiday,
    Timezone,
    AdjustmentReason,
    Vacation,
    SearchFilter,
  },
});

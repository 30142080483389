import ApiService from '../api.service';

const UsersResource = {
  index: (params) => ApiService.query('managers/users', params),
  show: (params) => ApiService.get(`managers/users/${params.id}`),
  register: (params) => ApiService.post('managers/users', params),
  update: (params) => ApiService.put(`managers/users/${params.id}`, params),
  deactivate: (params) => ApiService.put(`managers/users/${params.id}/deactivate`, params),
  import_drivers: (params) => ApiService.post('managers/users/import', params),
  remove_avatar: (id) => ApiService.put(`managers/users/${id}/remove_avatar`),
  search: (params) => ApiService.query(`managers/users/search?by_term=${params.term}`, params),
};

export default UsersResource;

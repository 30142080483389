import Advertence from '@/api/resources/advertences';
import i18n from '../../plugins/i18n';

const actions = {
  createAdvertence({ dispatch }, payload) {
    Advertence.create(payload).then(() => {
      dispatch('addSuccessMessage', i18n.t('successes.create_advertence'));
      dispatch('removeLoading');
    });
  },
};

export default {
  actions,
};

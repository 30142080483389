import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/notificacoes',
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        imageBackground: true,
        needToolbar: false,
        icon: 'none',
      },
    },
    {
      path: '/recuperar-senha',
      name: 'recoverPassword',
      component: () => import('@/views/RecoverPassword.vue'),
      meta: {
        imageBackground: true,
        needToolbar: false,
        icon: 'none',
      },
    },
    {
      path: '/motoristas',
      name: 'userList',
      component: () => import('@/views/Users.vue'),
      meta: {
        context: 'drivers',
        requiresAuth: true,
        needToolbar: true,
      },
    },
    {
      path: '/motoristas/:userId/configuracoes',
      name: 'userSettings',
      component: () => import('@/views/UserSettings.vue'),
      meta: {
        context: 'drivers',
        requiresAuth: true,
        needToolbar: true,
      },
    },
    {
      path: '/espelho-de-ponto',
      name: 'mirrorList',
      component: () => import('@/views/PointMirror.vue'),
      meta: {
        context: 'mirrorList',
        requiresAuth: true,
        needToolbar: true,
      },
    },
    {
      path: '/espelho-de-ponto/motorista/:id',
      name: 'mirrorListShow',
      component: () => import('@/views/PointMirrorShow.vue'),
      meta: {
        context: 'mirrorList',
        requiresAuth: true,
        needToolbar: true,
      },
    },
    {
      path: '/espelho-de-ponto/motorista/:userId/jornada/:workdayId',
      name: 'workdayShow',
      component: () => import('@/views/WorkdayShow.vue'),
      meta: {
        context: 'mirrorList',
        requiresAuth: true,
        needToolbar: true,
      },
    },
    {
      path: '/espelho-de-ponto/motorista/:userId/jornada/:workdayId/solicitacao-de-ajuste/:workdayUpdateId',
      name: 'mirrorListAdjustment',
      component: () => import('@/views/PointMirrorAdjustment.vue'),
      meta: {
        context: 'mirrorList',
        requiresAuth: true,
        needToolbar: true,
      },
    },
    {
      path: '/espelho-de-ponto/motorista/:driverId/jornada/:workdayId/ajuste-de-jornada',
      name: 'mirrorListAsjustmentByManager',
      component: () => import('@/views/PointMirrorAdjustmentByManager.vue'),
      meta: {
        context: 'mirrorList',
        requiresAuth: true,
        needToolbar: true,
      },
    },
    {
      path: '/folha-de-ponto/gerar-folha',
      name: 'timesheet',
      component: () => import('@/views/timesheet/Timesheet.vue'),
      meta: {
        context: 'timesheet',
        requiresAuth: true,
        needToolbar: true,
      },
    },
    {
      path: '/folha-de-ponto/rascunho',
      name: 'draftTimesheet',
      component: () => import('@/views/timesheet/DraftTimesheet.vue'),
      meta: {
        context: 'draftTimesheet',
        draft: true,
        requiresAuth: true,
        needToolbar: true,
      },
    },
    {
      path: '/folha-de-ponto/fechadas',
      name: 'closedTimesheet',
      component: () => import('@/views/timesheet/ClosedTimesheet.vue'),
      meta: {
        context: 'closedTimesheet',
        draft: false,
        requiresAuth: true,
        needToolbar: true,
      },
    },
    {
      path: '/folha-de-ponto/fechadas/imprimir/:timesheetId',
      name: 'Print',
      component: () => import('@/views/Print.vue'),
      meta: {
        requiresAuth: true,
        needToolbar: false,
      },
    },
    {
      path: '/notificacoes/',
      name: 'notifications',
      component: () => import('@/views/Notifications.vue'),
      meta: {
        requiresAuth: true,
        needToolbar: true,
      },
    },
    {
      path: '/configuracoes/',
      name: 'Setting',
      component: () => import('@/views/Setting.vue'),
      meta: {
        requiresAuth: true,
        needToolbar: true,
      },
    },
  ],
});

export default routes;

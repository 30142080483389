import ApiService from '../api.service';

const ManagersResource = {
  login: (params) => ApiService.post('managers/sign_in', params),
  show: () => ApiService.get('managers/me'),
  register: (params) => ApiService.post('managers', params),
  recoverPassword: (params) => ApiService.put('managers/recover_password', params),
};

export default ManagersResource;

const state = {
  currentBreadcrumbs: [],
};

const getters = {
  getCurrentBreadcrumbs: () => (state.currentBreadcrumbs),
};

const actions = {
  initCurrentBreadcrumbs({ commit }, payload) {
    commit('setCurrentBreadcrumbs', payload);
  },
  clearBreadcrumbs({ commit }) {
    commit('setCurrentBreadcrumbs', []);
  },
};

const mutations = {
  setCurrentBreadcrumbs($state, payload) {
    const stateCopy = $state;
    stateCopy.currentBreadcrumbs = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

import AdjustmentReason from '@/api/resources/adjustment_reasons';
import i18n from '../../plugins/i18n';

const state = {
  adjustmentReasons: [],
  adjustmentRegisterSuccess: false,
};

const getters = {
  getAdjustmentReasons: () => (state.adjustmentReasons),
  getAdjustmentRegisterSuccess: () => (state.adjustmentRegisterSuccess),
};

const actions = {
  fetchAdjustmentReasons({ commit, dispatch }, payload) {
    dispatch('initLoading');

    AdjustmentReason.index(payload).then((response) => {
      commit('setAdjustmentReasons', response);
      dispatch('removeLoading');
    });
  },

  toggleActivationAdjustmentReason({ commit, dispatch }, payload) {
    commit('setAdjustmentReasonRegisterSuccess', false);

    AdjustmentReason.toggleActivation(payload).then(() => {
      commit('setAdjustmentReasonRegisterSuccess', true);
      dispatch('addSuccessMessage', i18n.t('components.lists.adjustments.deactivate.success_message'));
      dispatch('removeLoading');
    });
  },

  updateAdjustmentReason({ commit, dispatch }, payload) {
    commit('setAdjustmentReasonRegisterSuccess', false);

    AdjustmentReason.update(payload).then(() => {
      commit('setAdjustmentReasonRegisterSuccess', true);
      dispatch('addSuccessMessage', i18n.t('components.lists.adjustments.edit.success_message'));
      dispatch('removeLoading');
    });
  },

  createAdjustmentReason({ commit, dispatch }, payload) {
    commit('setAdjustmentReasonRegisterSuccess', false);

    AdjustmentReason.create(payload).then(() => {
      commit('setAdjustmentReasonRegisterSuccess', true);
      dispatch('addSuccessMessage', i18n.t('components.lists.adjustments.register.success_message'));
      dispatch('removeLoading');
    });
  },
};

const mutations = {
  setAdjustmentReasons($state, payload) {
    const stateCopy = $state;
    stateCopy.adjustmentReasons = payload;
  },

  setAdjustmentReasonRegisterSuccess($state, payload) {
    const stateCopy = $state;
    stateCopy.adjustmentRegisterSuccess = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

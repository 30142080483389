<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      permanent
      fixed
      dark
      class="px-4 navigation-drawer"
      width="268"
      mini-variant-width="112"
      expand-on-hover
    >
      <navigation-drawer
        v-model="drawer"
      />
      <template
        v-slot:append
      >
        <v-list
          class="py-4"
        >
          <v-list-item @click="logout()">
            <v-list-item-action>
              <v-img
                :src="require('@/assets/icons/menu/log-out.svg')"
                class="image"
              />
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title class="content-color-default">
                {{ $t('components.menu.logout') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
  </div>
</template>
<script>
import NavigationDrawer from './NavigationDrawer.vue';

export default {
  name: 'ToolbarNav',
  components: {
    NavigationDrawer,
  },
  data() {
    return { drawer: false };
  },
  computed: {
    navIcon() {
      if (this.$route.meta.icon) { return this.$route.meta.icon; }
      return 'mdi-menu';
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-drawer {
  .content-color-default {
    color: $color_secondary_dark;
  }
}
</style>

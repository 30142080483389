import './plugins';
import VueTheMask from 'vue-the-mask';
import Vue from 'vue';
import VueGoogleCharts from 'vue-google-charts';
import VueDebounce from 'vue-debounce';
import VueGtm from '@gtm-support/vue2-gtm';
import ActionCableVue from 'actioncable-vue';
import App from './App.vue';
import i18n from './plugins/i18n';
import vuetify from './plugins/vuetify';
import store from './store';
import router from './routes';

Vue.use(VueGoogleCharts);
Vue.use(VueTheMask);
Vue.use(VueDebounce, {
  defaultTime: '400ms',
  fireOnEmpty: true,
  listenTo: 'input',
});
Vue.use(VueGtm, {
  id: 'GTM-MBT5F7F',
  defer: false,
  enabled: true,
  debug: false,
  loadScript: true,
  vueRouter: router,
  trackOnNextTick: false,
});

Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: 'all',
  connectionUrl: process.env.VUE_APP_CABLE_URL,
  connectImmediately: true,
});

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount('#app');

import Manager from '@/api/resources/managers';
import routes from '@/routes/appRoutes';
import i18n from '../../plugins/i18n';
import AuthStore from '../../common/auth.store';

const state = {
  manager: null,
};

const getters = {
  currentManager: () => (state.manager),
};

const actions = {
  login({ commit, dispatch }, payload) {
    Manager.login(payload).then((response) => {
      dispatch('createSession', response);
      commit('setManager', response);
      dispatch('removeLoading');
    });
  },

  refreshManager({ commit }) {
    const response = AuthStore.getCredentials();
    commit('setManager', response);
  },

  register({ commit, dispatch }, payload) {
    Manager.register(payload).then((response) => {
      commit('setManager', response);
      dispatch('createSession', response);
      dispatch('removeLoading');
    });
  },

  logout({ commit, dispatch }) {
    commit('setManager', null);
    dispatch('destroySession');
    dispatch('clearBreadcrumbs');
    routes.push('/login');
  },

  recoverPassword({ dispatch }, payload) {
    Manager.recoverPassword(payload).then(() => {
      dispatch('removeLoading');
      dispatch('addSuccessMessage', i18n.t('successes.recover_password'));
    });
  },
};

const mutations = {
  setManager($state, payload) {
    const stateCopy = $state;
    stateCopy.manager = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

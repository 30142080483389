import moment from 'moment-timezone';
import { format, parseISO } from 'date-fns';
import { pt } from 'date-fns/locale';

const TIMEZONE_OFFSET_START_SIZE = 24;
const TIMEZONE_OFFSET_END_SIZE = 26;

export default {
  // Formatting documentation https://date-fns.org/v2.21.1/docs/format
  methods: {
    currentMonth() {
      const dateToday = new Date();
      const dateFirstOfMonth = new Date();
      dateFirstOfMonth.setDate(1);

      return [this.formatDate(dateFirstOfMonth, 'yyyy-MM-dd'), this.formatDate(dateToday, 'yyyy-MM-dd')];
    },
    dayOfWeek(date, formatting) {
      if (!date) return '';

      return this.formatDate(date, formatting);
    },
    formatDate(date, params = 'dd/MM/yyyy') {
      if (!date) return '';

      return format(new Date(date), params, { locale: pt });
    },
    formatDateISO(date, params = 'dd/MM/yyyy') {
      if (!date) return '';

      return format(parseISO(date), params, { locale: pt });
    },
    removeTimezone(hour, date) {
      if (Number(hour) === 0) {
        return (24 - (date.getTimezoneOffset() / 60));
      }
      return (Number(hour) - (date.getTimezoneOffset() / 60));
    },
    formatDateTimeWithTimezone(dateTime) {
      moment.tz.setDefault(this.getTimezoneFromDateTime(dateTime));
      const date = moment(dateTime);
      const hours = this.formatTime(date.hours());
      const minutes = this.formatTime(date.minutes());
      return `${hours}:${minutes}`;
    },
    formatDateTime(dateTime) {
      const date = new Date(dateTime);
      const hours = this.formatTime(date.getHours());
      const minutes = this.formatTime(date.getMinutes());
      return `${hours}:${minutes}`;
    },
    formatDateTimeWithDate(dateTime, params = 'dd/MM') {
      const date = new Date(dateTime);
      const hours = this.formatTime(date.getHours());
      const minutes = this.formatTime(date.getMinutes());
      return `${format(parseISO(dateTime), params, { locale: pt })} ${hours}:${minutes}`;
    },
    formatDateTimeWithDateWithTimezone(dateTime, params = 'dd/MM') {
      moment.tz.setDefault(this.getTimezoneFromDateTime(dateTime));
      const date = moment(dateTime);
      const hours = this.formatTime(date.hours());
      const minutes = this.formatTime(date.minutes());
      return `${format(parseISO(dateTime), params, { locale: pt })} ${hours}:${minutes}`;
    },
    formatWithoutTimezone(dateTime) {
      const date = new Date(dateTime);
      const newDate = new Date(date.setHours(date.getHours() + (date.getTimezoneOffset() / 60)));
      return newDate.toLocaleDateString();
    },
    formatHour(milliseconds, showZeroTime = true) {
      if (!showZeroTime && Number(milliseconds) <= 0) return '-';

      const hours = milliseconds / (1000 * 60 * 60);
      const absoluteHours = Math.trunc(hours);
      let finalHours = absoluteHours;
      const minutes = Math.round((hours - absoluteHours) * 60);
      let finalMinutes = this.formatTime(minutes);

      if (finalMinutes === 60) {
        finalHours = Number(finalHours) + 1;
        finalMinutes = '00';
      }
      return `${this.formatTime(finalHours)}:${finalMinutes}`;
    },
    formatTime(value) {
      return value > 9 ? value : `0${value}`;
    },
    getTimezoneFromDateTime(dateTime) {
      if (!dateTime) {
        return moment.tz.guess();
      }
      const timezonePart = dateTime.slice(TIMEZONE_OFFSET_START_SIZE, TIMEZONE_OFFSET_END_SIZE);
      const timezoneOffSet = parseInt(timezonePart, 10);
      const timezoneOffSetInMin = timezoneOffSet * 60;
      const timezoneWithOffset = moment.tz.zonesForCountry('BR', true).filter((e) => e.offset === timezoneOffSetInMin);
      if (timezoneWithOffset.length > 0) {
        return timezoneWithOffset.at(0).name;
      }
      return moment.tz.guess();
    },
  },
};

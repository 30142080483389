const state = {
  tabs: [
    {
      name: 'Gerar Fechamento',
      route: 'timesheet',
    },
    {
      name: 'Rascunho',
      route: 'draftTimesheet',
    },
    {
      name: 'Concluídos',
      route: 'closedTimesheet',
    },
  ],
};

const getters = {
  getTabs: () => (state.tabs),
};

export default {
  state,
  getters,
};

import Holiday from '@/api/resources/holidays';
import i18n from '../../plugins/i18n';

const state = {
  holidays: [],
  holidayRegisterSuccess: false,
};

const getters = {
  getHolidays: () => (state.holidays),
  getHolidayRegisterSuccess: () => (state.holidayRegisterSuccess),
};

const actions = {
  fetchHolidays({ commit, dispatch }, payload) {
    Holiday.index(payload).then((response) => {
      commit('setHolidays', response);
      dispatch('removeLoading');
    });
  },

  createHoliday({ commit, dispatch }, payload) {
    commit('setHolidayRegisterSuccess', false);

    Holiday.create(payload).then(() => {
      dispatch('addSuccessMessage', i18n.t('components.lists.holiday.register.success_message'));
      commit('setHolidayRegisterSuccess', true);
      dispatch('removeLoading');
    });
  },

  updateHoliday({ commit, dispatch }, payload) {
    commit('setHolidayRegisterSuccess', false);

    Holiday.update(payload).then(() => {
      dispatch('addSuccessMessage', i18n.t('components.lists.holiday.edit.success_message'));
      commit('setHolidayRegisterSuccess', true);
      dispatch('removeLoading');
    });
  },

  deleteHoliday({ commit, dispatch }, payload) {
    commit('setHolidayRegisterSuccess', false);

    Holiday.delete(payload).then(() => {
      dispatch('addSuccessMessage', i18n.t('components.lists.holiday.delete.success_message'));
      commit('setHolidayRegisterSuccess', true);
      dispatch('removeLoading');
    });
  },
};

const mutations = {
  setHolidays($state, payload) {
    const stateCopy = $state;
    stateCopy.holidays = payload;
  },

  setHolidayRegisterSuccess($state, payload) {
    const stateCopy = $state;
    stateCopy.holidayRegisterSuccess = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

import BusinessUnit from '@/api/resources/business_units';
import i18n from '../../plugins/i18n';

const state = {
  business_units: [],
  unitRegisterSuccess: false,
};

const getters = {
  getBusinessUnits: () => (state.business_units),
  getUnitRegisterSuccess: () => (state.unitRegisterSuccess),
};

const actions = {
  fetchBusinessUnits({ commit }, payload) {
    BusinessUnit.index(payload).then((response) => {
      commit('setBusinessUnits', response);
    });
  },

  toggleActivationUnit({ commit, dispatch }, payload) {
    commit('setUnitRegisterSuccess', false);

    BusinessUnit.toggleActivation(payload).then(() => {
      commit('setUnitRegisterSuccess', true);
      dispatch('addSuccessMessage', i18n.t('components.lists.units.toggle_activation.success_message'));
      dispatch('removeLoading');
    });
  },

  updateUnit({ commit, dispatch }, payload) {
    commit('setUnitRegisterSuccess', false);

    BusinessUnit.update(payload).then(() => {
      commit('setUnitRegisterSuccess', true);
      dispatch('addSuccessMessage', i18n.t('components.lists.units.edit.success_message'));
      dispatch('removeLoading');
    });
  },

  createUnit({ commit, dispatch }, payload) {
    commit('setUnitRegisterSuccess', false);

    BusinessUnit.create(payload).then(() => {
      commit('setUnitRegisterSuccess', true);
      dispatch('addSuccessMessage', i18n.t('components.lists.units.register.success_message'));
      dispatch('removeLoading');
    });
  },
};

const mutations = {
  setBusinessUnits($state, payload) {
    const stateCopy = $state;
    stateCopy.business_units = payload;
  },

  setUnitRegisterSuccess($state, payload) {
    const stateCopy = $state;
    stateCopy.unitRegisterSuccess = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

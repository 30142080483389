import State from '@/api/resources/states';

const state = {
  states: [],
};

const getters = {
  getStates: () => (state.states),
};

const actions = {
  fetchStates({ commit }) {
    State.index().then((response) => {
      commit('setStates', response);
    });
  },
};

const mutations = {
  setStates($state, payload) {
    const stateCopy = $state;
    stateCopy.states = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

const state = {
  componentFatherDate: null,
  dateRange: [],
  page: 1,
  driverProfile: null,
  searchTerm: '',
};

const getters = {
  getComponentFatherDate: () => (state.componentFatherDate),
  getDateRange: () => (state.dateRange),
  getPage: () => (state.page),
  getDriverProfile: () => (state.driverProfile),
  getSearchTerm: () => (state.searchTerm),
};

const actions = {
  saveDateFatherComponent({ commit }, payload) {
    commit('setComponentFatherDate', payload);
  },

  saveDateRange({ commit }, payload) {
    commit('setDateRange', payload);
  },

  updatePage({ commit }, payload) {
    commit('setPage', payload);
  },

  saveDriverProfile({ commit }, payload) {
    commit('setDriverProfile', payload);
  },

  saveSearchTerm({ commit }, payload) {
    commit('setSearchTerm', payload);
  },
};

const mutations = {
  setComponentFatherDate($state, payload) {
    const stateCopy = $state;
    stateCopy.componentFatherDate = payload;
  },

  setDateRange($state, payload) {
    const stateCopy = $state;
    stateCopy.dateRange = payload;
  },

  setPage($state, payload) {
    const stateCopy = $state;
    stateCopy.page = payload;
  },

  setDriverProfile($state, payload) {
    const stateCopy = $state;
    stateCopy.driverProfile = payload;
  },

  setSearchTerm($state, payload) {
    const stateCopy = $state;
    stateCopy.searchTerm = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

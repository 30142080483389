<template>
  <div
    v-if="hasLoading"
    class="m-app-loader"
  >
    <div class="loader">
      <v-icon
        color="accent"
        size="100"
        class="truck"
      >
        mdi-truck-outline
      </v-icon>
      <div class="road" />
      <div class="dot-typing" />
      <div class="loading-information">
        <div class="text">
          {{ $t('components.loading.text') }}
        </div>
        <div class="dots">
          <div class="dot" />
          <div class="dot" />
          <div class="dot" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { mapState } from 'vuex';

export default {
  name: 'Loading',
  computed: {
    ...mapState([
      'Loading',
    ]),
    hasLoading() {
      return this.Loading.loading;
    },
  },
};
</script>

<style lang="scss" src="@/assets/scss/components/feedbacks/loading.scss" scoped/>

import Workday from '@/api/resources/workdays';
import routes from '@/routes/appRoutes';

const state = {
  workday: {
    id: null,
    created_at: null,
    drive_time: null,
    wait_time: null,
    rest_time: null,
    intra: null,
    available_time: null,
    meal_time: null,
    overnight_time: null,
    night_increase: null,
    full_extra_hour: null,
    half_extra_hour: null,
    dsr_respite: false,
    cct_respite: false,
    inter_workday_time: null,
    events: [{
      id: null,
      kind: null,
      duration: null,
      created_at: null,
      start_time: null,
      end_time: null,
      is_edited: null,
    }],
  },
  geoPoints: [],
};

const getters = {
  getWorkday: (state) => state.workday,
  getWorkdayGeoPoints: (state) => (state.geoPoints),
};

const actions = {
  fetchWorkday({ commit, dispatch }, payload) {
    Workday.show(payload).then((response) => {
      commit('setWorkday', response);
      dispatch('removeLoading');
    });
  },
  fetchWorkdayGeoPoints({ commit, dispatch }, payload) {
    Workday.geoPoints(payload).then((response) => {
      commit('setGeoPointsToWorkday', response);
      dispatch('removeLoading');
    });
  },
  createNewWorkday({ dispatch }, payload) {
    dispatch('initLoading');

    Workday.create(payload).then((response) => {
      routes.push({
        name: 'mirrorListAsjustmentByManager',
        params: {
          driverId: response.user_id,
          workdayId: response.id,
        },
      });
    });
  },
};

const mutations = {
  setWorkday($state, payload) {
    const workdayCopy = $state;
    workdayCopy.workday = payload;
  },
  setGeoPointsToWorkday($state, payload) {
    const stateCopy = $state;
    stateCopy.geoPoints = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

import Notification from '@/api/resources/notifications';

const state = {
  notifications: [],
  unreadNotificationMark: false,
};

const getters = {
  getNotifications: () => (state.notifications),
  getUnreadNotificationMark: () => (state.unreadNotificationMark),
};

const actions = {
  fetchNotifications({ commit, dispatch }, payload) {
    dispatch('initLoading');
    commit('setNotifications', []);

    Notification.index(payload).then((response) => {
      commit('setNotifications', response);
      dispatch('removeLoading');
    });
  },
  markNotificationAsRead({ commit }, payload) {
    commit('setNotificationAsRead', payload);

    Notification.read(payload);
  },
  markAllNotificationsAsRead({ commit, dispatch }) {
    dispatch('initLoading');

    Notification.readAll().then(() => {
      dispatch('removeLoading');
      commit('setUnreadNotificationMark', false);
      dispatch('fetchNotifications');
    });
  },
  updateUnreadNotificationMark({ commit }, payload) {
    commit('setUnreadNotificationMark', payload);
  },
};

const mutations = {
  setNotifications($state, payload) {
    const stateCopy = $state;
    stateCopy.notifications = payload;
  },
  setUnreadNotificationMark($state, boolean) {
    const stateCopy = $state;
    stateCopy.unreadNotificationMark = boolean;
  },
  setNotificationAsRead($state, payload) {
    const stateCopy = $state;
    const notifications = stateCopy.notifications.data;

    const index = notifications.findIndex((item) => item.id === payload.notification_id);

    if (index < 0) return;

    stateCopy.notifications.data[index].is_read = true;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

import ApiService from '../api.service';

const PointMirrorsResource = {
  index: (params) => ApiService.query('managers/workdays', params),
  fetchCategories: () => ApiService.get('/managers/driver_profiles?by_active=true'),
  fullPrint: (params) => ApiService.query('managers/workdays/print', params),
  fetchWorkdaysByUser: (params) => ApiService.query(`managers/workdays/${params.id}/fetch_workdays_by_user`, params),
  fetchGeoPoints: (params) => ApiService.query('managers/geo_points/fetch_geo_points', params),
};

export default PointMirrorsResource;

import '@mdi/font/scss/materialdesignicons.scss';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import pt from 'vuetify/es5/locale/pt';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // default - only for display purposes
    values: {
      custom_drive: {
        component: () => import('@/components/icons/Drive.vue'),
      },
      custom_rest: {
        component: () => import('@/components/icons/Rest.vue'),
      },
      custom_wait: {
        component: () => import('@/components/icons/Wait.vue'),
      },
      custom_meal: {
        component: () => import('@/components/icons/Meal.vue'),
      },
      custom_overnight: {
        component: () => import('@/components/icons/Overnight.vue'),
      },
      custom_available: {
        component: () => import('@/components/icons/Available.vue'),
      },
      custom_geopoint: {
        component: () => import('@/components/icons/Available.vue'),
      },
    },
  },
  theme: {
    themes: {
      light: {
        primary: '#212121',
        text_color: '#fff',
        accent: '#FF4900',
        list_item: '#BBBBBB',
        color_icon_dark: '#2d2d2d',
        setings_tabs: '#E1E1E1',
        color_background: '#F1F1F1',
        list_placeholder: '#E87368',
        color_subtitle: '#979797',
      },
    },
  },
  options: {
    customProperties: true,
  },
  lang: {
    locales: { pt },
    current: 'pt',
  },
});

import Mirror from '@/api/resources/point_mirrors';

const state = {
  mirrors: [],
  categories: [],
  workdays: {},
  printableWorkdays: [],
  geoPoints: [],
};

const getters = {
  getMirrors: () => (state.mirrors),
  getCategories: () => (state.categories),
  getPrintableWorkdays: () => (state.printableWorkdays),
  getWorkdays: () => (state.workdays),
  getGeoPoints: () => (state.geoPoints),
};

const actions = {
  fetchMirrors({ commit, dispatch }, payload) {
    Mirror.index(payload).then((response) => {
      commit('setMirrors', response);
      dispatch('removeLoading');
    });
  },
  fetchCategories({ commit }) {
    Mirror.fetchCategories().then((response) => {
      commit('setCategories', response);
    });
  },
  fetchFullPrint({ commit, dispatch }, payload) {
    Mirror.fullPrint(payload).then((response) => {
      commit('setFullPrint', response);
      dispatch('removeLoading');
    });
  },
  fetchWorkdaysByUser({ commit, dispatch }, payload) {
    Mirror.fetchWorkdaysByUser(payload).then((response) => {
      commit('setWorkdays', response);
      dispatch('removeLoading');
    });
  },

  fetchGeoPoints({ commit, dispatch }, payload) {
    Mirror.fetchGeoPoints(payload).then((response) => {
      commit('setGeoPoints', response);
      dispatch('removeLoading');
    });
  },
  resetGeoPoints({ commit }) {
    commit('setGeoPoints', []);
  },
};

const mutations = {
  setMirrors($state, payload) {
    const stateCopy = $state;
    stateCopy.mirrors = payload;
  },
  setCategories($state, payload) {
    const stateCopy = $state;
    stateCopy.categories = payload;
  },
  setFullPrint($state, payload) {
    const stateCopy = $state;
    stateCopy.printableWorkdays = payload;
  },
  setWorkdays($state, payload) {
    const stateCopy = $state;
    stateCopy.workdays = payload;
  },
  setGeoPoints($state, payload) {
    const stateCopy = $state;
    stateCopy.geoPoints = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

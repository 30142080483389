<template>
  <div class="navigation-drawer">
    <v-list
      class="py-4"
    >
      <v-list-item>
        <v-list-item-action>
          <v-img
            :src="require('@/assets/icons/menu/menu-24px.svg')"
          />
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title class="content-color-default">
            {{ getMenu('menu') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list
      class="pt-12"
      dense
    >
      <v-list-item
        v-for="item in items"
        :key="item.title"
        class="my-6"
        @click="goTo(item)"
      >
        <v-list-item-action>
          <v-badge
            v-if="item.context === 'notifications' && showUnreadNotificationMark"
            overlap
            dot
            bordered
            color="pink"
          >
            <v-icon :class="renderClass(item.destination)">
              {{ item.icon }}
            </v-icon>
          </v-badge>

          <v-icon
            v-else
            :class="renderClass(item.destination)"
          >
            {{ item.icon }}
          </v-icon>
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title :class="renderClass(item.destination)">
            {{ item.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
import formats from '@/mixins/formats';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'NavigationDrawer',
  mixins: [formats],
  data() {
    return {
      showUnreadNotificationMark: false,
      items: [
        {
          title: this.getMenu('notification'),
          context: 'notifications',
          icon: 'mdi-bell',
          destination: ['notifications'],
        },
        {
          title: this.getMenu('list_drivers'),
          context: 'drivers',
          icon: 'mdi-account',
          destination: ['userList', 'userSettings'],
        },
        {
          title: this.getMenu('point-mirror'),
          context: 'mirrorList',
          icon: 'mdi-clipboard-account',
          destination: ['mirrorList', 'mirrorListShow', 'workdayShow', 'mirrorListAdjustment', 'mirrorListAsjustmentByManager'],
        },
        {
          title: this.getMenu('timesheet'),
          context: 'timesheet',
          icon: 'mdi-clipboard-text-outline',
          destination: ['timesheet', 'draftTimesheet', 'closedTimesheet'],
        },
        {
          title: this.getMenu('setting'),
          context: 'settings',
          icon: 'mdi-cog',
          destination: ['Setting'],
        },
      ],
    };
  },
  channels: {
    UnreadNotificationsChannel: {
      received(data) {
        this.updateUnreadNotificationMark(data.new_notification);

        if (data.new_notification) {
          if (!('Notification' in window)) {
            console.log(this.$t('components.lists.notifications.unsupported')); // eslint-disable-line no-console
          } else if (Notification.permission === 'granted') {
            const notification = new Notification(this.$t('components.lists.notifications.new'));
            console.log(notification); // eslint-disable-line no-console
          } else if (Notification.permission !== 'denied') {
            Notification.requestPermission((permission) => {
              if (permission === 'granted') {
                const notification = new Notification(this.$t('components.lists.notifications.new'));
                console.log(notification); // eslint-disable-line no-console
              }
            });
          }
        }
      },
    },
  },
  computed: {
    ...mapGetters([
      'currentManager',
      'getUnreadNotificationMark',
    ]),
    manager() {
      if (this.currentManager) {
        return this.currentManager;
      }
      return {};
    },
  },
  watch: {
    getUnreadNotificationMark(value) {
      this.showUnreadNotificationMark = value;
    },
  },
  mounted() {
    this.refreshManager();
    this.$cable.subscribe({
      channel: 'UnreadNotificationsChannel',
      enterprise_id: this.currentManager.enterprise_id,
    });
  },
  methods: {
    ...mapActions([
      'saveDateFatherComponent',
      'saveDateRange',
      'updatePage',
      'updateUnreadNotificationMark',
      'refreshManager',
      'saveDriverProfile',
    ]),
    goTo(item) {
      this.saveDateFatherComponent(item.context);
      this.saveDateRange(this.currentMonth());
      this.updatePage(1);
      this.saveDriverProfile(0);

      if (this.$route.name !== item.destination[0]) {
        this.$router.push({ name: item.destination[0] });
      }
    },
    renderClass(destination) {
      if (destination.includes(this.$route.name)) {
        return 'content-color-selected';
      }
      return 'content-color-default';
    },
    getMenu(value) {
      return this.$t(`components.menu.${value}`);
    },
  },
};
</script>

<style lang="scss" scoped>
  .navigation-drawer {
    height: 100%;

    .content-color-default {
      color: $color_secondary_dark;
    }
    .content-color-selected {
      color: $color_primary;
    }
  }
</style>

import Vacation from '@/api/resources/vacations';
import i18n from '../../plugins/i18n';

const state = {
  vacations: [],
  vacationRegisterSuccess: false,
};

const getters = {
  getVacations: () => (state.vacations),
  getVacationRegisterSuccess: () => (state.vacationRegisterSuccess),
};

const actions = {
  fetchVacations({ commit, dispatch }, payload) {
    Vacation.index(payload).then((response) => {
      commit('setVacations', response);
      dispatch('removeLoading');
    });
  },

  deleteVacation({ commit, dispatch }, payload) {
    commit('setVacationRegisterSuccess', false);

    Vacation.delete(payload).then(() => {
      commit('setVacationRegisterSuccess', true);
      dispatch('addSuccessMessage', i18n.t('components.lists.vacations.delete.success_message'));
      dispatch('removeLoading');
    });
  },

  updateVacation({ commit, dispatch }, payload) {
    commit('setVacationRegisterSuccess', false);

    Vacation.update(payload).then(() => {
      commit('setVacationRegisterSuccess', true);
      dispatch('addSuccessMessage', i18n.t('components.lists.vacations.edit.success_message'));
      dispatch('removeLoading');
    });
  },

  createVacation({ commit, dispatch }, payload) {
    commit('setVacationRegisterSuccess', false);

    Vacation.create(payload).then(() => {
      commit('setVacationRegisterSuccess', true);
      dispatch('addSuccessMessage', i18n.t('components.lists.vacations.register.success_message'));
      dispatch('removeLoading');
    });
  },
};

const mutations = {
  setVacations($state, payload) {
    const stateCopy = $state;
    stateCopy.vacations = payload;
  },

  setVacationRegisterSuccess($state, payload) {
    const stateCopy = $state;
    stateCopy.vacationRegisterSuccess = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

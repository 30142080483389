import ApiService from '../api.service';

const BusinessUnitsResource = {
  index: (params) => ApiService.query('managers/business_units', params),
  create: (params) => ApiService.post('managers/business_units', params),
  update: (params) => ApiService.put(`managers/business_units/${params.id}`, params),
  toggleActivation: (params) => ApiService.put(`managers/business_units/${params.id}/toggle_activation`, params),
};

export default BusinessUnitsResource;

import Timesheet from '@/api/resources/timesheets';
import i18n from '../../plugins/i18n';

const state = {
  timesheetDraft: null,
  timesheets: null,
  specificTimesheet: null,
  printTimesheet: null,
};

const getters = {
  getTimesheetDraft: () => (state.timesheetDraft),
  getTimesheets: () => (state.timesheets),
  getSpecificTimesheet: () => (state.specificTimesheet),
  getPrintTimesheet: () => (state.printTimesheet),
};

const actions = {
  fetchTimesheets({ commit, dispatch }, payload) {
    Timesheet.index(payload).then((response) => {
      commit('setTimesheets', response);
      dispatch('removeLoading');
    });
  },
  showTimesheet({ commit, dispatch }, payload) {
    Timesheet.show(payload).then((response) => {
      commit('setTimesheetShow', response);
      dispatch('removeLoading');
    });
  },
  draft({ commit, dispatch }, payload) {
    Timesheet.draft(payload).then((response) => {
      commit('setTimesheetDraft', response);
      dispatch('removeLoading');
    });
  },
  createTimesheet({ dispatch }, payload) {
    Timesheet.create(payload).then((response) => {
      dispatch('fetchTimesheets', { by_status: response.status });
      dispatch('addSuccessMessage', i18n.t('successes.timesheet.create'));
      dispatch('removeLoading');
    });
  },
  updateTimesheet({ dispatch }, payload) {
    Timesheet.update(payload).then((response) => {
      dispatch('fetchTimesheets', { by_status: response.status });
      dispatch('addSuccessMessage', i18n.t('successes.timesheet.update'));
      dispatch('removeLoading');
    });
  },
  deleteTimesheet({ dispatch }, payload) {
    Timesheet.delete(payload).then(() => {
      dispatch('addSuccessMessage', i18n.t('successes.timesheet.delete'));
      dispatch('fetchTimesheets', { by_status: 'open' });
      dispatch('removeLoading');
    });
  },
  printTimesheet({ commit, dispatch }, payload) {
    dispatch('initLoading');
    Timesheet.printWorkdays(payload).then((response) => {
      commit('setPrintTimesheet', response);
      dispatch('removeLoading');
    });
  },
};

const mutations = {
  setTimesheetDraft($state, payload) {
    const stateCopy = $state;
    stateCopy.timesheetDraft = payload;
  },
  setTimesheets($state, payload) {
    const stateCopy = $state;
    stateCopy.timesheets = payload;
  },
  setTimesheetShow($state, payload) {
    const stateCopy = $state;
    stateCopy.specificTimesheet = payload;
  },
  setPrintTimesheet($state, payload) {
    const stateCopy = $state;
    stateCopy.printTimesheet = payload;
  },
  setCancel($state) {
    const stateCopy = $state;
    stateCopy.timesheetDraft = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

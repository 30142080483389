import ApiService from '../api.service';

const DriverProfileResource = {
  index: (params) => ApiService.query('/managers/driver_profiles', params),
  create: (params) => ApiService.post('/managers/driver_profiles/', params),
  toggleActivation: (params) => ApiService.put(`managers/driver_profiles/${params.id}/toggle_activation`, params),
  update: (params) => ApiService.put(`/managers/driver_profiles/${params.id}`, params),
};

export default DriverProfileResource;

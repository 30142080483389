import Branch from '@/api/resources/branches';
import i18n from '../../plugins/i18n';

const state = {
  branches: [],
  branchRegisterSuccess: false,
};

const getters = {
  getBranches: () => (state.branches),
  getBranchRegisterSuccess: () => (state.branchRegisterSuccess),
};

const actions = {
  fetchBranches({ commit }, payload) {
    Branch.index(payload).then((response) => {
      commit('setBranches', response);
    });
  },

  toggleActivationBranch({ commit, dispatch }, payload) {
    commit('setBranchRegisterSuccess', false);

    Branch.toggleActivation(payload).then(() => {
      commit('setBranchRegisterSuccess', true);
      dispatch('addSuccessMessage', i18n.t('components.lists.branches.toggle_activation.success_message'));
      dispatch('removeLoading');
    });
  },

  updateBranch({ commit, dispatch }, payload) {
    commit('setBranchRegisterSuccess', false);

    Branch.update(payload).then(() => {
      commit('setBranchRegisterSuccess', true);
      dispatch('addSuccessMessage', i18n.t('components.lists.branches.edit.success_message'));
      dispatch('removeLoading');
    });
  },

  createBranch({ commit, dispatch }, payload) {
    commit('setBranchRegisterSuccess', false);

    Branch.create(payload).then(() => {
      commit('setBranchRegisterSuccess', true);
      dispatch('addSuccessMessage', i18n.t('components.lists.branches.register.success_message'));
      dispatch('removeLoading');
    });
  },
};

const mutations = {
  setBranches($state, payload) {
    const stateCopy = $state;
    stateCopy.branches = payload;
  },

  setBranchRegisterSuccess($state, payload) {
    const stateCopy = $state;
    stateCopy.branchRegisterSuccess = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

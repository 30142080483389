<template>
  <v-app class="app">
    <toolbar-nav v-if="needToolbar" />
    <div
      class="app-content"
      :style="appStyle"
    >
      <v-breadcrumbs
        :items="breadcrumbs"
        divider=">"
      >
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            :to="item.to"
            :exact="item.exact"
            :class="classBreadcrumb(item)"
          >
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <loading />
      <v-main id="main-app">
        <v-container fluid>
          <router-view />
        </v-container>
      </v-main>
    </div>
    <snackbar />
    <!-- <v-footer app></v-footer> -->
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import image from '@/assets/login_background.png';
import ToolbarNav from './components/navs/ToolbarNav.vue';
import Loading from './components/feedbacks/Loading.vue';
import Snackbar from './components/feedbacks/Snackbar.vue';

export default {
  name: 'App',
  components: {
    ToolbarNav,
    Loading,
    Snackbar,
  },
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    ...mapGetters([
      'hasSession',
      'currentManager',
      'hasMessage',
      'getCurrentBreadcrumbs',
    ]),
    appStyle() {
      if (this.$route.meta.imageBackground) {
        return `background: url(${image});
                background-size: cover;
                height: 100%;`;
      }

      if (!this.$route.meta.needToolbar) {
        return 'padding: 0;';
      }

      return 'margin-left: 90px;';
    },
    needToolbar() {
      return (this.$route.meta.needToolbar && this.hasSession);
    },
    breadcrumbs() {
      return this.getCurrentBreadcrumbs;
    },
  },
  watch: {
    hasSession(hasSession) {
      if (!hasSession) {
        this.$router.push('/login');
      } else {
        this.$router.push('/notificacoes');
      }
    },
  },
  mounted() {
    this.restoreSession();
  },
  methods: {
    ...mapActions(['restoreSession']),
    classBreadcrumb(item) {
      if (item.disabled) {
        return 'breadcrumb-disabled';
      }
      return 'breadcrumb';
    },
  },
};
</script>

<style lang="scss" scoped>
.app {
  .app-content {
    .breadcrumb {
      background: $color-primary;
      font-family: 'Acumin-Pro-Regular';
    }
    .breadcrumb-disabled {
      background: $color-primary;
      font-family: 'Acumin-Pro-Bold';
    }
    .breadcrumb, .breadcrumb-disabled {
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
</style>

import Timezone from '@/api/resources/timezones';

const state = {
  timezones: [],
};

const getters = {
  getTimezones: () => (state.timezones),
};

const actions = {
  fetchTimezones({ commit, dispatch }) {
    Timezone.index().then((response) => {
      commit('setTimezones', response);
      dispatch('removeLoading');
    });
  },
};

const mutations = {
  setTimezones($state, payload) {
    const stateCopy = $state;
    stateCopy.timezones = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

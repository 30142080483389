import WorkdayUpdate from '@/api/resources/workday_updates';
import routes from '@/routes/appRoutes';
import i18n from '../../plugins/i18n';

const state = {
  workday: {},
  workdays: [],
};

const getters = {
  getWorkdayUpdate: () => (state.workday),
  getWorkdayUpdates: () => (state.workdays),
};

const actions = {
  fetchWorkdayUpdate({ commit, dispatch }, payload) {
    WorkdayUpdate.show(payload).then((response) => {
      commit('setWorkdayUpdate', response);
      dispatch('removeLoading');
    });
  },
  fetchWorkdayUpdates({ commit, dispatch }, payload) {
    WorkdayUpdate.index(payload).then((response) => {
      commit('setWorkdaysUpdates', response);
      dispatch('removeLoading');
    });
  },
  approve({ commit, dispatch }, payload) {
    WorkdayUpdate.approve_update(payload).then((response) => {
      commit('setWorkdayUpdate', response);
      dispatch('removeLoading');
    });
  },
  reject({ commit, dispatch }, payload) {
    WorkdayUpdate.reject_update(payload).then((response) => {
      commit('setWorkdayUpdate', response);
      dispatch('removeLoading');
    });
  },
  createWorkdayUpdate({ dispatch }, payload) {
    WorkdayUpdate.create(payload).then((response) => {
      dispatch('addSuccessMessage', i18n.t('successes.workday_update.create'));
      dispatch('removeLoading');

      routes.push({
        name: 'workdayShow',
        params: {
          userId: response.user_id,
          workdayId: response.id,
        },
      });
    });
  },
  changeDsrSuppressed({ dispatch }, payload) {
    dispatch('initLoading');

    WorkdayUpdate.changeDsrSuppressed(payload).then(() => {
      dispatch('addSuccessMessage', i18n.t('successes.workday_update.create'));
      dispatch('removeLoading');
    });
  },

  turnWorkdayIntoDayOff({ dispatch }, payload) {
    dispatch('initLoading');

    WorkdayUpdate.changeToDayOff(payload).then((response) => {
      dispatch('addSuccessMessage', i18n.t('successes.workday_update.dsr_suppressed'));
      dispatch('removeLoading');

      routes.push({
        name: 'workdayShow',
        params: {
          userId: response.user_id,
          workdayId: response.id,
        },
      });
    });
  },
};

const mutations = {
  setWorkdayUpdate($state, payload) {
    const stateCopy = $state;
    stateCopy.workday = payload;
  },
  setWorkdaysUpdates($state, payload) {
    const stateCopy = $state;
    stateCopy.workdays = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

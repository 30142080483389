import User from '@/api/resources/users';
import i18n from '../../plugins/i18n';

const state = {
  users: [],
  usersBySearch: [],
  registerSuccess: false,
  driver: {
    id: null,
    name: null,
    cpf: null,
    category: null,
    state_acronym: null,
    state_id: null,
    phone: null,
    email: null,
    admission_date: null,
  },
};

const getters = {
  getUsers: () => (state.users),
  getUsersBySearch: () => (state.usersBySearch),
  getRegisterSuccess: () => (state.registerSuccess),
  getDriver: () => (state.driver),
};

const actions = {
  fetchUsersByName({ commit }, payload) {
    User.search(payload).then((response) => {
      commit('setUsers', response);
    });
  },

  fetchUsers({ commit, dispatch }, payload) {
    User.index(payload).then((response) => {
      commit('setUsers', response);
      dispatch('removeLoading');
    });
  },

  registerUser({ dispatch, commit }, payload) {
    commit('setRegisterSuccess', false);

    User.register(payload).then(() => {
      dispatch('removeLoading');
      dispatch('addSuccessMessage', i18n.t('components.lists.drivers.register.success_message'));
      commit('setRegisterSuccess', true);
    });
  },

  updateDriver({ dispatch, commit }, payload) {
    commit('setRegisterSuccess', false);

    User.update(payload).then(() => {
      dispatch('removeLoading');
      dispatch('addSuccessMessage', i18n.t('components.lists.drivers.edit.success_message'));
      commit('setRegisterSuccess', true);
    });
  },

  removeUserAvatar({ dispatch, commit }, payload) {
    commit('setRegisterSuccess', false);

    User.remove_avatar(payload).then(() => {
      dispatch('removeLoading');
      dispatch('addSuccessMessage', i18n.t('components.lists.drivers.edit.remove_avatar'));
      commit('setRegisterSuccess', true);
    });
  },

  deactivateUser({ dispatch, commit }, payload) {
    commit('setRegisterSuccess', false);
    dispatch('initLoading');

    User.deactivate(payload).then(() => {
      dispatch('removeLoading');
      dispatch('addSuccessMessage', i18n.t('components.lists.drivers.deactivate.success_message'));
      commit('setRegisterSuccess', true);
    });
  },

  importDrivers({ dispatch, commit }, payload) {
    commit('setRegisterSuccess', false);

    User.import_drivers(payload).then(() => {
      dispatch('removeLoading');
      commit('setRegisterSuccess', true);
    });
  },

  fetchStoreDriver({ dispatch, commit }, payload) {
    User.show(payload).then((response) => {
      commit('setStoreDriver', response);
      dispatch('removeLoading');
    });
  },
};

const mutations = {
  setUsers($state, payload) {
    const stateCopy = $state;
    stateCopy.users = payload;
  },

  setUsersBySearch($state, payload) {
    const stateCopy = $state;
    stateCopy.usersBySearch = payload;
  },

  setRegisterSuccess($state, payload) {
    const stateCopy = $state;
    stateCopy.registerSuccess = payload;
  },

  setStoreDriver($state, payload) {
    const stateCopy = $state;
    stateCopy.driver = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

import ApiService from '../api.service';

const TimesheetsResource = {
  draft: (params) => ApiService.query('managers/timesheets/draft', params),
  create: (params) => ApiService.post('managers/timesheets/', params),
  index: (params) => ApiService.query('managers/timesheets/', params),
  delete: (params) => ApiService.delete(`managers/timesheets/${params.id}`),
  show: (params) => ApiService.get(`managers/timesheets/${params.id}`),
  update: (params) => ApiService.put(`managers/timesheets/${params.id}`, params),
  printWorkdays: (params) => ApiService.get(`managers/timesheets/${params.id}/print_workday`),
};

export default TimesheetsResource;
